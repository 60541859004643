import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FormExample() {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    college: "",
    level: "",
    age: "",
    gender: "",
    whatKnow: "",
    whyJoin: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isLoading) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
      } else {
        setIsLoading(true);
        console.log(formData);
        // Simulate an API request
        axios
          .post(`${process.env.REACT_APP_API_URL}/ambassador/register`, formData)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              toast.success(res.data.message);
              setFormData({
                fName: "",
                lName: "",
                email: "",
                phone: "",
                city: "",
                state: "",
                college: "",
                level: "",
                age: "",
                gender: "",
                whatKnow: "",
                whyJoin: "",
              });
              setValidated(false);
            } else {
              toast.error(res.data.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        // Reset validation after submission
      }
    }
  };

  return (
    <section id="form">
      <ToastContainer />
      <Form
        id="form"
        noValidate
        validated={validated}
        style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}
        onSubmit={handleSubmit} // Attach handleSubmit to the form
      >
        <header className="section-header">
          <h3>Registration</h3>
        </header>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Harsh"
              name="fName"
              value={formData.fName}
              onChange={handleChange}
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid first name
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Singh"
              name="lName"
              value={formData.lName}
              onChange={handleChange}
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid last name
            </Form.Control.Feedback>
          </Form.Group>
        </Row>



        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustomUsername">
            <Form.Label>Email</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                placeholder="harshsingh@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={isLoading} // Disable input during loading
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom03">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="9892999999"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              minLength={10}
              maxLength={10}
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number without country code.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom04">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Kolkata"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom05">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              placeholder="West Bengal"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom06">
            <Form.Label>College</Form.Label>
            <Form.Control
              type="text"
              placeholder="IIT Kharagpur"
              name="college"
              value={formData.college}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid college.
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom07">
            <Form.Label>Year of Study</Form.Label>
            <Form.Control
              type="text"
              placeholder="1st year"
              name="level"
              value={formData.level}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid level.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom10">
            <Form.Label>Age</Form.Label>
            <Form.Control
              type="number"
              placeholder="22"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid age.
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom11">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              disabled={isLoading} // Disable input during loading
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a gender.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom08">
            <Form.Label>What do you know about us?</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="I know ..."
              name="whatKnow"
              value={formData.whatKnow}
              onChange={handleChange}
              style={{ resize: "none" }}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
            Please provide this information.
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <br />
          <br />
          <br />
          <Form.Group as={Col} md="6" className="mb-3 mb-md-0" controlId="validationCustom09">
            <Form.Label>Why do you want to join?</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="I want to join NSSC because ..."
              name="whyJoin"
              value={formData.whyJoin}
              onChange={handleChange}
              style={{ resize: "none" }}
              required
              disabled={isLoading} // Disable input during loading
            />
            <Form.Control.Feedback type="invalid">
              Please provide this information.            
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button
          type="submit"
          style={{ width: "100%" }}
          disabled={isLoading} // Disable button during loading
        >
          {isLoading ? "Submitting..." : "Register"}
        </Button>
      </Form>
    </section>
  );
}

export default FormExample;
